<template>
  <v-container fluid fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12 sm10 md10>
        <v-sheet color="white" elevation="20" class="pa-2">
          <v-data-table :headers="headers[$vuetify.breakpoint.xsOnly]" :items="items"
            no-data-text="No hay productos registrados" :items-per-page="-1" item-key="store,code" mobile-breakpoint="0"
            group-by="code" show-group-by :search="search" sort-by="category,product_name" class="table-cursor mt-3"
            @click:row="handleClick">
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" hide-details append-icon="mdi-magnify" outlined
                  placeholder="Buscar producto ..." clearable />
                <v-spacer></v-spacer>
                <v-btn color="orange" v-if="supplier_selected.code" fab x-small dark
                  @click="dialog_smart = !dialog_smart">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
              <v-avatar>
                <v-img :src="getImagePhoto(item.account, '000000', item.picture)"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
              <b>{{ item.product_name }}</b>
              <span v-if="$vuetify.breakpoint.xsOnly">
                <small> ({{ item.category }})</small><br />
                <small> {{ item.size }} {{ item.uom }} </small>
              </span>
            </template>

            <template v-slot:[`item.size`]="{ item }">
              {{ item.size + " " + item.uom }} <br />
            </template>
            <template v-slot:[`item.normalized_size`]="{ item }">
              {{ item.cost / item.size }} <br />
            </template>

            <template v-slot:[`item.cost`]="{ item }">
              {{ "$ " + parseFloat(item.cost).toLocaleString(2) }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-flex>
    </v-layout>

    <ProductForm :table="table" :item="item" :dialog="dialog" :newItem="newItem" :categories="categories" :store="store"
      @close="dialog = false" :subcategories="subcategories" :brands="brands" :suppliers="this.supplier_selected.code"
      @refresh="refresh()" />

    <v-dialog v-model="dialog_smart" right :width="smart_width">
      <v-toolbar dark color="#128C7E" class="fixed-bar">
        <v-btn x-small fab class="mr-2" @click="s_expand = !s_expand">
          <v-icon>mdi-arrow-split-vertical</v-icon>
        </v-btn>
        <v-toolbar-title>Smart List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_smart = !dialog_smart">mdi-close</v-icon>
      </v-toolbar>
      <v-toolbar color="teal lighten-4" class="fixed-bar-b">
        <v-select rounded :items="smart_categories" v-model="catSelected" outlined dense hide-details="auto"
          @change="changeMenu" />
        <v-spacer></v-spacer>
        <v-btn x-small color="warning" fab @click="newForm()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-tabs
        class="align-with-title"
        background-color="white"
        light
        show-arrows
        centered
        slider-color="orange"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="category in smart_categories"
          :key="category"
          :href="'#tab-' + category"
          @click="changeMenu(category)"
        >
          {{ category }}
        </v-tab>
      </v-tabs> -->

      <!-- <v-expansion-panels>
      <v-expansion-panel
         v-for="category in smart_categories"
          :key="category"
      >
        <v-expansion-panel-header>
           {{ category }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

      <v-data-table :headers="headers[!s_expand]" :items="smart_lst_selected" :items-per-page="15" item-key="store,code"
        sort-by="name" :search="search_smart" mobile-breakpoint="0" class="table-cursor elevation-1"
        loading-text="Cargando ...">
        <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
          <v-avatar v-if="item.picture">
            <v-img :src="getImagePhoto(item.account, '000000', item.picture)"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.product_name }}</b>
          <span v-if="$vuetify.breakpoint.xsOnly">
            <small> ({{ item.category }})</small><br />
            <small> {{ item.size }} {{ item.uom }} </small>
          </span>
        </template>
        <template v-slot:[`item.size`]="{ item }">
          {{ item.size + " " + item.uom }} <br />
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
        </template>

        <template v-slot:[`item.addto`]="{ item }">
          <v-icon small class="mr-2" @click="add_to_supplier(item)">
            mdi-arrow-left
          </v-icon>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="edit_smart_lst(item)">
            mdi-pencil
          </v-icon>
        </template>

      </v-data-table>

      <v-bottom-navigation dark app>
        <v-toolbar color="#128C7E">
          <v-row justify="center">
            <v-col md="12" cols="12">
              <v-text-field v-model="search_smart" hide-details append-icon="mdi-magnify" outlined rounded dense
                placeholder="Buscar producto Smart ..." @keypress="update_selected()" />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-bottom-navigation>
    </v-dialog>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import ProductForm from "../components/RawMaterialForm.vue";
import createStore from "../utils/createStore.js";

export default {
  components: { ProductForm },
  data() {
    return {
      search_smart: "",
      s_expand: true,
      smart_width: 800,
      dialog_smart: false,
      smart_categories: [],
      smart_lst_selected: [],
      header_size: true,
      table: "raw_material",
      search: "",
      search_supplier: "",
      dialog_suppliers: true,
      errors: [],
      store: "",
      storesLst: [],
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      byCategories: [],
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      supplier_dialog: false,
      supplier_selected: createStore(),
      headers: {
        false: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
            groupable: false,
          },
          {
            text: "Proveedor",
            align: "start",
            sortable: true,
            value: "storeName",
            dataType: "text",
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
          },
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "brand",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Tamaño",
            align: "center",
            sortable: true,
            value: "size",
            dataType: "text",
            groupable: false,
          },
          {
            text: "V UOM",
            align: "center",
            sortable: true,
            value: "cost_uom",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
            groupable: false,
          },
          {
            text: "V UOM",
            align: "center",
            sortable: true,
            value: "price_uom",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
            groupable: false,
          },


        ],
        true: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "storeName",
            dataType: "text",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },

        ],
      },
      headers_suppliers: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          width: 40,
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
      ],
      items: [],
      smart_lst: [],
      smart_ctgry: [],
      item: createItem(),
      edit: false,
      catSelected: "",
    };
  },
  mounted() {
    this.storesLst = window.stores;
    //this.supplier.account="000000";
    this.get_items();
    this.get_suppliers();

  },
  methods: {
    update_selected() {
      this.smart_lst_selected = this.smart_lst;
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    get_suppliers() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "suppliers",
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        console.log(data)
        this.loading_status = false;
        this.suppliers = data;
      });
    },
    add_to_supplier(e) {
      this.item = { ...e };
      this.store = this.supplier_selected.code;
      this.item.store = this.supplier_selected.code;
      this.item.supplier = this.supplier_selected.code;
      this.valid = false;
      this.newItem = false;
      this.dialog = true;
    },
    get_smart_lst() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [{ field: "store", operator: "=", value: "000000" }],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.smart_ctgry = ctgry;
        this.smart_categories = Object.keys(ctgry).sort();
        this.smart_lst_selected = data;

        console.log(ctgry, data);
        this.loading_status = false;
        this.smart_lst = data;
      });
    },
    handleClick(data) {
      this.store = data.store;
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    get_items(e) {

      if (e) {
        this.supplier_selected = e;
      }
      this.items = [];
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "store", operator: "<>", value: "000000" },

        ],
      };

      webserver("get_table", qry, (data) => {
        this.loading_status = false;

        if (data.length !== 0) {
          data.forEach((rg) => {
            if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
            if (this.suppliers.find(item => item.code == rg.store)) {
              rg.storeName = this.suppliers.find(item => item.code == rg.store).name;
            }
            //console.log(rg)

          });

          var ctgry = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          for (const [key] of Object.entries(ctgry)) {
            ctgry[key].sort(function (a, b) {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          this.byCategories = ctgry;
          this.categories = Object.keys(ctgry).sort();

          var sbctgry = data.reduce(function (r, a) {
            r[a.subcategory] = r[a.subcategory] || [];
            r[a.subcategory].push(a);
            return r;
          }, Object.create(null));
          this.subcategories = Object.keys(sbctgry).sort();

          var brnd = data.reduce(function (r, a) {
            r[a.brand] = r[a.brand] || [];
            r[a.brand].push(a);
            return r;
          }, Object.create(null));
          this.brands = Object.keys(brnd).sort();
          this.items = data;
          //console.log(this.items)
        }
        if (this.$vuetify.breakpoint.xsOnly) {
          this.dialog_suppliers = false;
        }
        this.get_smart_lst();
      });
    },
    newForm() {
      this.item = createItem();
      this.store = "000000";
      this.item.account = this.$store.state.profile.account;
      this.item.store = "000000";
      this.item.supplier = "SCP";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    edit_smart_lst(data) {
      this.item = createItem();
      this.item = { ...data };
      this.store = "000000";
      this.item.account = this.$store.state.profile.account;
      this.item.store = "000000";
      this.item.supplier = "SCP";
      this.valid = false;
      this.newItem = false;
      this.dialog = true;
    },
    refresh() {
      this.dialog = false;
      // if (this.newItem) {
      //   this.get_smart_lst();
      // } else {
      //   this.get_items();
      // }
      this.get_smart_lst();
      this.get_items();
      this.update_selected();
    },
    getImagePhoto: function (account, code, src) {
      var url =
        "https://mayorista.app/photos/" + account + "/000000/" + src;
      return url;
    },
    changeMenu(e) {
      this.search_smart = "";
      this.smart_lst_selected = this.smart_ctgry[e];
      console.log(this.smart_lst_selected, this.smart_ctgry, e);
    },
  },
  watch: {
    s_expand: function (e) {
      if (e) {
        this.smart_width = 800;
      } else {
        this.smart_width = 450;
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}

.centered-input input {
  text-align: center;
}

.table-cursor tbody tr:hover {
  cursor: pointer;
}



.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}
</style>
